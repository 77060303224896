<template>
    <div>
        <bhLoading :show="loading" />
        <EditDrawer @update="updateApp" />
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Appointment</div>
                    <a-icon @click="addAppointment" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <div :class="keyI != 0 ? 'mt-5':''" v-for="(key, keyI) in Object.keys(orderAppointments)" :value="key+keyI" :key="key+keyI">
                <div style="color:#212529; font-size:15px; font-weight:bold;">{{key.toUpperCase()}}</div>
                <template v-if="orderAppointments[key].length">
                    <div class="dF jSB mt-3" v-for="appointment in orderAppointments[key]" :value="appointment.id" :key="appointment.id">
                        <div class="dF">
                            <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#FFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            </div>
                            <div>
                                <div style="color:var(--orange);">{{appointment.title}}</div>
                                <div v-if="appointment.notes && appointment.notes != ''" style="color:#000">{{appointment.notes}}</div>
                                <div style="color:#9EA0A5">{{key == 'later' ? `${convertDate(appointment.date)} | ${getHour(appointment.startTime, appointment.endTime)}`:getHour(appointment.startTime, appointment.endTime)}}</div>
                                <div class="dF" v-if="appointment.assignTo && appointment.assignTo.length">
                                    <div v-if="appointment.opportunity && appointment.opportunity.contact">{{appointment.assignTo && appointment.assignTo.length ? `${getContactName()},`:`${getContactName()}`}}</div>
                                    <div class="ml-2" v-for="(user, userI) in appointment.assignTo" :key="user.id ? user.id+userI : user+userI" :value="user.id+userI ? user.id : user+userI">
                                        <div v-if="user.id && user.firstName && user.lastName">{{userI != appointment.assignTo.length - 1 ? `${getUser(user.id)},`:`${getUser(user.id)}`}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dF mr-3">
                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                <template slot="title">Quick View</template>
                                <a-icon type="eye" @click="$store.commit('OPEN_PREVIEW_MODAL', {type:'appointment', object:appointment})" class="icon-button mr-2" />
                            </a-tooltip>
                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                <template slot="title">Edit Appointment</template>
                                <svg @click="editAppointment(appointment.id)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                            </a-tooltip>
                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                <template slot="title">Delete Appointment</template>
                                <i @click="deleteAppointment(appointment)" style="color:var(--danger)" class="fe fe-trash-2" />
                            </a-tooltip>
                        </div>
                    </div>
                </template>
                <div v-else>{{`You don't have any appointments ${key}`}}</div>
            </div>
        </a-card>
    </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import EditDrawer from '@/components/contacts/EditDrawer'
import index from '../layout/SubBar/index.vue'
export default {
  components: { index , EditDrawer, bhLoading},
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            update:0,
            loading:false
        }
    },
    computed:{
        owner() {
            return this.$store.state.user.user || {}
        },
        appointments(){
            let appointments = []
            let time = this.update
            if (this.$store.state.contacts.appointments && this.$store.state.contacts.appointments.length) {
                let storeAppointments = this.$store.state.contacts.appointments
                appointments = storeAppointments.filter(x => {
                    if(x.opportunity && x.opportunity.id && x.opportunity.id == this.id) {
                        return x
                    }
                })
            }
            return appointments
        },
        orderAppointments() {
            let today = new Date().getDate()
            let month = new Date().getMonth()
            let year = new Date().getFullYear()
            let appointments = {}
            if (this.appointments.length) {
                appointments.today = this.appointments.filter(x => {
                    if (x.date && x.date != 0 && new Date(x.date).getDate() == today && new Date(x.date).getMonth() == month && new Date(x.date).getFullYear() == year) {
                        return x
                    }
                })
                appointments.tomorrow = this.appointments.filter(x => {
                    if (x.date && x.date != 0 && new Date(x.date).getDate() == today+1 && new Date(x.date).getMonth() == month && new Date(x.date).getFullYear() == year) {
                        return x
                    }
                })
				let tempApt = [].concat(appointments.today, appointments.tomorrow)
				appointments.later = this.appointments.filter(apt => {
					let index = tempApt.findIndex(a => a.id == apt.id)
					return index == -1
				})
            }
            return appointments
        },
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        opportunities() {
            return this.$store.state.contacts.opportunities
        }
    },
    methods:{
        getContactName() {
            if (this.id != '') {
                let find = this.opportunities.find(x => x.id == this.id)
                if (find && find.contact && find.contact.firstName && find.contact.lastName && find.contact.firstName.trim() && find.contact.lastName.trim()) return `${find.contact.firstName} ${find.contact.lastName}`
                else if (find && find.email) return `${find.email}`
                else return ''
            } else return ''
        },
        updateApp() {
            this.update = Date.now()
        },
        getUser(id) {
            if (id == this.$store.state.user.user.id) {
                return 'You'
            } else {
                let find = this.$store.state.contacts.users.find(x => x.id == id)
                if (find) return `${find.firstName} ${find.lastName}`
                else return 'N/A'
            }
        },
        getHour(start, end) {
            let startTime = new Date(start)
            let endTime = new Date(end)
            return `${startTime.getHours()}:${startTime.getMinutes() < 10 ? '0'+startTime.getMinutes() : startTime.getMinutes()} - ${endTime.getHours()}:${endTime.getMinutes() < 10 ? '0'+endTime.getMinutes() : endTime.getMinutes()}`
        },
        convertDate(num){
            if (num != 0) {
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
                return `${month}/${day}/${year}`
            } else return 'N/A'
        },
        deleteAppointment(item) {
			const id = item.id
            let self = this
            if (this.$p < 40 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to delete Appointments')
            }
            this.$confirm({
                title: "Delete Appointment",
                content: h => <div>Do you want to delete this appointment?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
                    self.$api.delete(`/appointments/:instance/${id}`).then( ({data}) => {
                        self.$store.commit('DELETE_APPOINTMENT', data)
                        self.loading = false
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        editAppointment(id) {
            this.$store.commit('OPEN_EDIT_DRAWER', {type:'appointment', id:id})
            console.log('editAppointment', id)
        },
        addAppointment() {
            this.$store.commit('ADD_NEW', 'Appointment')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', this.id)
        }
    }
}
</script>

<style scoped>
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange)
}
</style>
